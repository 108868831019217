
import { useEffect } from 'react';
// import Head from 'next/head';
//= Layout
import MainLightLayout from '../../layouts/main-light';
//= Components
import StickyBar from '../../components/Common/StickyBar';
import FixedSearch from '../../components/Common/FixedSearch';
import Header from '../../components/Corporate/Header3';
import Hero from '../../components/Corporate/Hero2';
import Services from '../../components/Corporate/Services';
import Process from '../../components/Corporate/Process';
import Testimonials from '../../components/Corporate/Testimonials';
// import Brands from '../../components/Corporate/Brands';
// import Pricing from '../../components/Corporate/Pricing';
// import Footer from "../../components/Footers/CorporateFooter";
import Footer from "../../components/Footers/MainFooter";


const ServicesCorporate = () => {
  useEffect(() => {
    document.body.classList.add('index-corporate');

    const removeClasses = [
      'land-demo2', 'index-bus1',
      'index-restaurant', 'index-arch',
      'index-freelancer', 'cr-agency',
      'index-main', 'mobile-app',
      'gr-orange-bg', 'nft-market'
    ];

    document.body.classList.remove(...removeClasses);
  }, []);

  return (
    <>
      {/* <Head> */}
        <title>Grandco Service</title>
      {/* </Head> */}

      <MainLightLayout>
        <StickyBar />
        <FixedSearch />
        <Header />
        <Services services={true} />
        <Hero />
        <Process services={true} />
        <Testimonials additionalClass="bg-gray section-padding pb-0" services={true} />
        {/* <Brands additionalClass="bg-gray section-padding pt-80" /> */}
        {/* <Pricing /> */}
        {/* <Footer /> */}
        <Footer footerClass="main-footer bg-dark-blue bg-img" footerBg={false} business={true} />

      </MainLightLayout>
    </>
  )
}

export default ServicesCorporate;